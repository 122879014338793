<template>

  <div>
    <!-- MAIN SLIDER-->
    <v-sheet class="d-flex justify-center" :color="wsBACKGROUND" >
      <v-sheet  class="pa-16 pt-16" color="transparent" width="1200">
        <v-row>
          <v-col v-if="displayHide(['sm'])" cols="12" xs="12" sm="12" md="7" lg="7" xl="7" class="pl-16" >
            <div class="d-flex justify-center align-center fill-height">

              <v-img transition="xxx" class="wsRounded" aspect-ratio="1.5" src="@/assets/img/about_us.png" />
            </div>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
            <div class="d-flex align-center fill-height ">
              <div>
                <div>

                  <div class="d-flex">
                    <v-icon class="mr-5" x-large :color="wsDARKER">mdi-map-marker</v-icon>
                    <div>
                      <h1 class="font-weight-light" :style="`color: ${wsDARK}`">Адреса</h1>
                      <h4 class=" font-weight-light">54025, вул. Бузніка 5, Миколаїв, Україна</h4>
                    </div>
                  </div>
                  <div class="d-flex mt-5">
                    <v-icon class="mr-5" x-large :color="wsDARKER">mdi-phone</v-icon>
                    <div>
                      <h1 class="font-weight-light" :style="`color: ${wsDARK}`">Телефон</h1>
                      <h4 class=" font-weight-light">+38 (063) 753 43 30</h4>
                    </div>
                  </div>
                  <div class="d-flex mt-5">
                    <v-icon class="mr-5" x-large :color="wsDARKER">mdi-email</v-icon>
                    <div>
                      <h1 class="font-weight-light" :style="`color: ${wsDARK}`">Email</h1>
                      <h4 class=" font-weight-light">info@westudy.ua</h4>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-sheet>

<!--    <v-sheet class="d-flex justify-center" >-->
<!--      <v-sheet  class=" wsRounded my-16" color="transparent" width="1200">-->
<!--        <iframe class="wsRounded" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10891.026601864423!2d31.9615606!3d46.9664711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3e8f32c9642e1246!2sWeStudy!5e0!3m2!1sen!2sua!4v1648939570353!5m2!1sen!2sua"-->
<!--                width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>-->
<!--      </v-sheet>-->
<!--    </v-sheet>-->



  </div>
</template>

<script>
export default {
  name: "HomeContacts"
}
</script>

<style scoped>

</style>